import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 className="page-head-title">
            A knack for playing with code and a love for well crafted and deeply
            thought out experiences.
          </h2>
          <figure className="kg-card kg-image-card kg-width-small">
            <Img
              fluid={data.profile.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Living my best life!</figcaption>
          </figure>
          <h3 id="dynamic-styles">Thanks for visiting</h3>
          <p>
            {" "}
            <em>
              <em>Living my life by Ephesians 2:10. Ask me all about it.</em>
            </em>
          </p>
          <h5>What excites me</h5>
          <p>
            I've been designing for more than 3 years and I'm excited about
            products driven by measurable impact. I like using design to
            supercharge product growth and moving the needle on the metrics that
            matter in any project I’m in.
          </p>
          <h5> My experience</h5>
          <p>
            I've previously designed at Meta, Quora, RVezy and Transparent
            Kitchen (now Tablz) and freelanced on my own.
          </p>
          <p>
            I spent the whole of 2021 heads down building my startup{" "}
            <a href="https://www.yourhomeboard.com/" target="_blank">
              Homeboard
            </a>{" "}
            with support from the great folks at{" "}
            <a href="https://www.nextcanada.com/" target="_blank">
              Next Canada
            </a>{" "}
            and{" "}
            <a href="https://www.antler.co/canada" target="_blank">
              Antler Canada
            </a>
            .
          </p>
          <h5>Education</h5>
          <p>
            uOttawa Software Engineering with an option in Technology Management
            '21
          </p>

          <h5>Things I've been a part of</h5>
          <p> Antler, Next 36, BlacksWhoDesign</p>

          <h5> On my free time</h5>
          <p>
            Jamming out to music, time with the Holy Spirit, cooking a decent
            steak
          </p>
          <h5> Some Recs</h5>
          <p>Currently watching Criminal Minds on Disney+.</p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profile: file(relativePath: { eq: "profile.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
